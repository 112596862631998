.syotimer-cell {
  display: inline-flex;
  padding-right: 5px;
  .syotimer-cell__value {
    padding-right: 5px;
  }
}
.duration-picker-container{
  font-size: 14px;
}
.duration-picker-container {
  margin-top: 10px;
  margin-bottom: 10px;
  select {
    color: white;
    display: inline-block;
    border-radius: 15px;
    padding: 4px 18px;
    box-sizing: content-box;
    background: #39ADAA;
    border: none;
    font-size: 14px;
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 3px;
  }

  select:focus-visible {
    outline: none;
  }
}

.poll-message {
  .poll-button {
    width: 100%;
    background: #eee;
    color: black;
    text-align: left;
    margin-bottom: 5px;
  }

  .ctext-wrap-content {
    text-align: left !important;
  }
}

#create-polls-modal {
  .modal-content {
    overflow: scroll;

    .option-item {
      position: relative;
      .add-icon {
        font-size: 25px;
      }

      .delete-option {
        position: absolute;
        bottom: 0;
        right: 0px;
        font-size: 24px;
      }
    }

    .add-option-btn {
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #6C6C70;
      i {
        font-weight: bold;
      }
      .add-text {
        padding-left: 5px; font-size: 13px; font-weight: 100; padding-top: 1px;
      }
    }
  }
}

@media (max-width: 370px) {
  .duration-picker-container {
    .duration-picker-mins {
      display: block;
      margin-top: 15px;
    }
  }
}
