#lifetime-duration-label {
  font-size: 14px;font-weight: 300;color: #6C6C90;
}

#show-seed-modal .seed-value {
  width: 100%;
  padding: 5px;
  border-color: #00A0B0;
}

#pills-setting {
  background: url('../images/template.jpg');
  background-size: 200%;
  background-repeat: no-repeat;

  .accordion-item {
    background-color: transparent;
    &:first-of-type {
      .accordion-button {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }
    }

    .accordion-button {
      background-color: #f5f7fb;
      .title-text {
        background: #39ADAA;
        border-radius: 10px;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 70px;
        width: 100%;
        color: white;
        &::before {
          content: '';
          width: 50px;
          height: 50px;
          background: url('../images/tab-personal-info.png');
          background-size: 50px;
          background-repeat: no-repeat;
          position: absolute;
          top: 5px;
          left: 30px;
        }
      }
    }
    #security1 {
      .title-text {
        &::before {
          background: url('../images/tab-security.png');
          background-size: 50px;
        }
      }
    }

    #settings-1 {
      .title-text {
        &::before {
          background: url('../images/tab-setting.png');
          background-size: 50px;
        }
      }
    }

    .content-text-bold {
      font-size: 14px;
      margin-bottom: 0px;
    }

    .accordion-button:not(.collapsed) {
      background-color: #f5f7fb;
      .title-text {
        background-color: #f5f7fb;
        text-align: left;
        padding: 10px 0px;
        color: #495057;
        &::before {
          content: '';
          width: 0px;
          height: 0px;
        }
      }
    }

    .accordion-body {
      padding: 1px 0px;
      background-color: #f5f7fb;
      .allow-animation:active {
        background-color: rgba(0, 160, 176, 0.32);
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 0.1s linear;
      }

      .all-my-info {
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0 16px 16px;
        background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        .each-setting-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .setting-icon {
            font-size: 25px;
            color: #6c6c70;
            padding-right: 4px;
          }
          .left-block {
            display: flex;
            align-items: center;
            .square {
              background: #39ADAA;
              box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
              border-radius: 5px;
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            .title-text {
              font-size: 14px;
              font-weight: 300;
            }
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }


  .top-page {
    color: white;
    position: relative;
    h3, h4 {
      color: white;
    }
    h1 {
      color: #39ADAA;
    }
    .more-option {
      position: absolute;
      top: 17px;
      right: 15px;
      button {
        font-size: 24px;
        padding: 0px;
        color: white;
      }
    }

    .payment-button {
      color: white;
    }

    .top-button {
      background-color: white;
      border-radius: 100%;
      width: 42px;
      height: 42px;
      line-height: 42px;
      margin: auto;
      margin-bottom: 5px;
    }

    .alert-fund {
      padding: 5px;
      font-size: 16px;
      color: red;
      text-align: center;
      margin-bottom: 0px;
      background: #f6eeeeb5;
      border-radius: 20px;
    }

    .row.wallet-group {
      --bs-gutter-x: 0px;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 7px;
      .wallet-col {
        padding: 10px;
        border: 1px dotted;
        border-radius: 5px;
        background-color: #ffffff2e;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        .btn-link {
          color: #a6b0cf;
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0;
          height: 0;
        }
      }
    }
    .row.avatar-group {
      display: flex;
      justify-content: center;
      padding: 15px;
      position: relative;
      .avatar-img {
        background-color: white;
        border-radius: 100%;
        box-shadow: 0px 0px 13px 1px #e2dbdb;
        -webkit-box-shadow: 0px 0px 13px 1px #e2dbdb;
        -moz-box-shadow: 0px 0px 13px 1px #e2dbdb;
        width: 200px;
        height: 200px;
        padding: 0;
      }
      .change-avatar {
        position: absolute;
        bottom: 25px;
        left: calc(50% - 25px);
        font-size: 25px;
        color: #40adaa;
        background: #ffffffc9;
        width: 49px;
        height: 35px;
        border-radius: 100%;
      }
    }
  }
}
#set-timezone-box {
  .select {
    position: relative;
    width: 100%;
  }
  .select svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: #9098a9;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }
  .select select {
    -webkit-appearance: none;
    color: #757272;
    padding: 7px 40px 7px 12px;
    width: 100%;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    background: transparent;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
  }

  .select select:focus {
    outline: none;
    box-shadow: none;
  }
  .sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
}

@media (min-width: 500px) {
  #pills-setting {
    background: url("../images/template.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -150px;
  }
}

@media (min-width: 700px) {
  #pills-setting {
    background: url("../images/template.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -350px;
  }
}
