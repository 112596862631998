.user-profile-sidebar {
  .top-block {
    background-color: #00a0b03d;
    .avatar-img {
      background-color: white;
      border-radius: 100%;
      box-shadow: 0px 0px 13px 1px #e2dbdb;
      -webkit-box-shadow: 0px 0px 13px 1px #e2dbdb;
      -moz-box-shadow: 0px 0px 13px 1px #e2dbdb;
      width: 280px;
      height: 280px;
      margin: 10px 0px 12px;
      padding: 0;
    }
    .fullname-block {
      justify-content: center;
      display: flex;
      align-items: center;

      .top-fullname {
        color: #fff;
        font-size: 20px;
      }

      .edit-icon {
        font-size: 20px;
        padding-left: 10px;
        color: white;
      }
    }

    .edit-wrapper {
      display: none;
      .edit-fullname-block {
        justify-content: center;
        display: flex;
        align-items: center;

        .fullname-input {
          background-color: unset;
          color: white;
          font-size: 16px;
          font-weight: bold;
          border: 1px solid;
          &:focus-visible {
            outline: none;
          }
        }

        .save-icon {
          font-size: 20px;
          padding-left: 10px;
          color: white;
        }
      }
    }
  }
  .user-profile-desc {
    .colorDisplay {
      background-color: #00a0b03d;
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }
    .accordion-button {
      &::after {
        display: none;
      }
    }
  }
}
