.confirm-friend-modal {
  position: fixed;
  background-color: white;
  color: #495057;
  z-index: 99;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: none;

  .main-container {
    padding: 15px;

    .header {
      display: flex;
      align-items: center;

      .close-btn {
        color: #495057;
      }

      .title {
        display: block;
        margin: auto;
        padding-right: 20px;
        font-size: 20px;
      }
    }

    .avatar-img {
      background-color: white;
      border-radius: 100%;
      box-shadow: 0px 0px 13px 1px #e2dbdb;
      -webkit-box-shadow: 0px 0px 13px 1px #e2dbdb;
      -moz-box-shadow: 0px 0px 13px 1px #e2dbdb;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px auto;
    }

    .each-block {
      text-align: center;
      margin-bottom: 20px;
    }

    .introduction {
      border: 1px dotted;
      display: inline-block;
      padding: 1px 5px;
    }

    .warning-text {
      text-align: center;
    }

    .button-block {
      i {
        margin-right: 5px;
      }
      margin-top: 50px;

      .accept-btn {
        width: 100%;
        background-color: #03A9F4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 10px;
        border: none;
        color: white;
        font-weight: bold;
      }
      .ignore-btn {
        width: 100%;
        background-color: #ff5722;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 10px;
        font-weight: bold;
        color: white;
      }
    }
  }
}
