#edit-secured-book {
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.edit-secured-book-main {
  background-color: #f5f7fb;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: black;

  .wrapper-header {
    height: 60px;
    padding: 8px 0px;
    border-bottom: 1px solid;
    border-color: white;
  }

  .top-header {
    display: flex;
    align-items: center;
    color: black;
  }

  #download-secured-book {
    color: #39ADAA;
  }

  p.title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
    width: 100%;
    padding: 3px 0px;
  }

  input.title {
    margin-bottom: 0px;
    word-break: break-all;
    width: 100%;
    border: none;
    background-color: unset;
    border-bottom: 1px solid #7b7c7d;
    border-radius: 0px;
    padding: 3px 0px;
    outline: none;
  }

  input.title:focus {
    border-color: #00A0B0;
  }
  input.title::placeholder {
    color: #7b7c7d;
  }

  .continue-btn {
    border: none;
    &.active {
      color: #39ADAA;
      background-color: transparent;
    }
  }
}
.spreadsheet-wrapper {
  width: 100vw;
  overflow: scroll;
  height: calc(100vh - 60px);
  .spreadsheet {
    margin: 0px;
    table, td, th, tr {
      border: 0.05rem solid rgba(197, 197, 197, 0.486);
      font-size: 1rem;
      height: 2.5rem;
      border-spacing: 0rem;
    }

    th {
      font-weight: 500;
    }

    thead {
      /* background-color: rgb(160, 180, 206); */
      background-color: #f1f1f1;
      /* color: #fff; */
    }

    td {
      min-width: 80px;
      padding: 0.2rem 0.5rem;
      background: white;
    }

    th > .dropdown {
      float: right;
    }

    tbody th {
      min-width: 5rem;
      background-color: #f1f1f1;
      text-align: center;
    }

    /* Dropdown Button */
    .dropbtn {
      background-color: #e2e2e2d0;
      color: rgba(0, 0, 0, 0.5);
      padding: 0rem 0.3rem;
      /* /* font-size: 1.2rem; */
      border: none;
      cursor: pointer;
      transition: ease 0.4s;
    }

    /* Dropdown button on hover & focus */
    .dropbtn:hover,
    .dropbtn:focus {
      background-color: #d0d0d0;
      /* visibility: visible; */
    }

    .dropbtn img {
      height: 2rem;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
      position: relative;
      display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 175px;
      box-shadow: 0 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.1);
      z-index: 1;
    }

    /* Links inside the dropdown */
    .dropdown-content p {
      color: rgb(133, 132, 132);
      padding: 5px;
      margin-bottom: 0px;
      font-weight: 400;
      text-decoration: none;
      display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content p:hover {
      background-color: #ddd;
    }

    /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
    .show {
      display: block;
    }

    .column-header-span {
      cursor: pointer;
    }
  }
}
