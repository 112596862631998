.edit-photo-modal {
  position: fixed;
  background-color: white;
  color: #495057;
  z-index: 9999;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: none;

  .main-container {
    padding: 0;

    .header {
      padding: 5px 15px;
      display: flex;
      align-items: center;

      .close-btn {
        color: #495057;
      }

      .title {
        display: block;
        margin: auto;
        padding-right: 20px;
        font-size: 20px;
      }
    }
  }
}
