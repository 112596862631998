.sign-up-background-common {
  background: url('../images/BG1.jpg');
  background-size: auto 135%;
}

.sign-up-promotion {
  height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: white;

  .promotion-block {
    display: flow-root;
    margin: 0 30px;
    padding-top: 20%;
  }

  .each-item {
    display: flex;
    align-items: center;
  }

  .icon-img {
    width: 50px;
  }

  .content {
    padding-left: 20px;
    color: #000
  }

  .check-btn {
    right: 90px;
    position: absolute;
    bottom: 90px;
  }

  a.check-btn:before {
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 50px;
    height: 50px;
    margin-top: 8px;
    background-color: #39ADAA;
  }


  a.check-btn:after {
    left: 17px;
    width: 16px;
    height: 8px;
    margin-top: 27px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
    content: "";
    position: absolute;
    top: 50%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
  }
}
#user-sign-up {
  .main {
    height: 100vh;
    min-height: 550px;
    width: 100%;
    position: relative;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    .wrapper {
      padding: 20px 10px;
      .header {
        margin-bottom: 10px;
        margin-top: 60px;

        .logo-block {
          display: flex;
          justify-content: center;
          align-items: center;
          .logo {
            width: 90px;
          }
          .welcome-text {
            text-align: center;
            margin-left: 10px;
            color: #202633;
            font-size: 18px;
          }
        }
      }

      .body {
        margin-top: 20px;
        z-index: 100;
        position: relative;
        display: grid;
        text-align: center;

        .container-checkbox {
          display: block;
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container-checkbox input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: white;
          border: 1px solid #ccc;
        }

        /* On mouse-over, add a grey background color */
        .container-checkbox:hover input ~ .checkmark {
          background-color: white;
        }

        /* When the checkbox is checked, add a blue background */
        .container-checkbox input:checked ~ .checkmark {
          background-color: #39ADAA;
          border: none;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container-checkbox input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container-checkbox .checkmark:after {
          left: 7px;
          top: 3px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }


        .switch-block {
          margin-top: 20px;
          color: #000;
          font-size: 16px;

          a.signin-btn {
            color: #39ADAA;
            font-weight: bold;
            padding-left: 5px;
          }
        }

        .term-block {
          .each-item {
            color: #000;
            display: flex;
            margin-bottom: 10px;
          }

          margin-bottom: 15px;
        }

        .continue-btn {
          margin-top: 20px;
          margin: auto;
          background-color: #ccc;
          pointer-events: none;
        }

        .btn-active {
          background-color: #39ADAA;
          color: white;
          pointer-events: auto;
        }
      }
    }
  }

  /*modal*/
  .term-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

     /* Modal Content */
    .term-modal-wrapper {
      background-color: #1d2226;
      margin: auto;
      color: white;
      width: 80%;
      font-family: "Poppins", sans-serif;
      border: 1px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(to right, #0ce4e1, #d855ff);
    }

    .term-modal-content {
      padding: 10px 20px;
      ul {
        padding: 0px;
        li {
          margin-bottom: 5px;
        }
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      margin-right: 10px;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 370px) {
  #user-sign-up {
    .main {
      .body {
        .term-block {
          .each-item {
            display: inline-table!important;
          }
        }
      }
    }
  }
}
