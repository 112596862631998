#edit-avatar {
  height: 100vh;
  min-height: 550px;
  font-family: "Poppins", sans-serif;
  color: black;

  .top-header {
    padding: 15px 20px 0;
    .title {
      font-size: 20px;
      display: inline-table;
      margin-top: 0px;
    }

    .back-btn {
      width: 20px;
      margin-right: 10px;
      margin-bottom: 5px;
      filter: brightness(0);
    }

    .continue-btn {
      color: #00a0b0;
      font-size: 16px;
      float: right;
      font-weight: bold;
      padding-top: 4px;
      background: none;
      border: none;
    }

    .continue-btn:disabled,
    .continue-btn[disabled] {
      color: #9e9e9e;
    }
  }

  .main-content {
    height: calc(100vh - 61px);
    overflow-y: scroll;
    .show-image-block {
      position: relative;
      .avatar-img {
        width: 100vw;
        height: 100vw;
      }
      .delete-btn {
        position: absolute;
        bottom: -10px;
        background: red;
        color: white;
        width: 140px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        left: calc(50% - 70px);
      }
    }
    .content-block {
      padding: 15px;
      .notice {
        padding-top: 12px;
        font-weight: bold;
      }
      .list {
        display: grid;
        grid-template-columns: 31.4% auto auto;
        column-gap: 10px;
        row-gap: 10px;
        .each-item {
          img {
            width: 100%;
          }
        }
        .take-photo {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #39ADAA;
          width: 100%;
          i {
            color: white;
            font-size: 45px;
          }
        }
      }
    }
  }
}
