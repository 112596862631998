#pills-contacts, #pills-requests {
  .top-header {
    display: flex;
    align-items: center;
    line-height: 0.8;
  }
  .back-to-contact-btn {
    padding-right: 15px;
    font-size: 29px;
  }
  .custom-color-image {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    display: block;
    float: left;
    margin-right: 5px;
    background-color: #00a0b03d;
    border: 1px solid white;
  }

  .contact-list {
    padding-bottom: 68px!important;
    background-color: #f5f7fb;

    li {
      .user-item {
        display: flex;
        align-items: center;
        .avatar-img {
          background-color: white;
          border-radius: 100%;
          border: 1px solid #00a0b03d;
          width: 45px;
          height: 45px;
          padding: 0;
          margin-right: 15px;
        }
      }
      .dropdown {
        .dropdown-toggle {
          font-size: 20px;
        }
      }
    }

    li:active {
      background-color: rgba(0, 160, 176, 0.32);
      -webkit-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 0.2s linear;
    }
    .room-name {
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
    }


    .inline-block {
      display: -webkit-inline-box;
    }

    .app-member-btn {
      background-color: #39ADAA;
      color: white;
      border-radius: 10px;
      padding: 0px 10px;
      width: fit-content;
      font-size: 12px;
    }
    .verify-status-block {
      background-color: #ffd166;
      margin-right: 10px;
      border-radius: 10px;
      padding: 0px 10px;
      width: fit-content;
      color: black;
      font-size: 12px;
    }
  }
}
