[effect="ripple"] {
  --ripple-background: black;
  --ripple-duration: 300ms;
  --ripple-border-radius: 50%;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.scroll {
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.popover {
  font-family: 'Poppins', sans-serif !important;
  border: none !important;
  min-width: 222px !important;
  box-shadow: rgb(0 0 0 / 15%) 4px 4px 8px, rgb(0 0 0 / 25%) 0px 5px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
  margin-left: -15px !important;
}

.modal.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100%, 0);
}

ul.room-list {
  li {
    a {
      padding: 10px 15px;
      .avatar-title {
        width: 80%!important;
        height: 80%!important;
        margin-top: 5px;
      }
    }
  }
  li:active {
    background-color: rgba(0, 160, 176, 0.32);
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }
}

.pull-right {
  float: right;
}

.hidden {
  display: none;
}

.font-bold {
  font-weight: bold;
}

.warning-text {
  color: #ff5722 !important;
}

.gray-bg-standard {
  background-color: rgba(118, 118, 128, 0.1)!important;
}

#main-app-color, .main-app-color {
  color: #00a0b0 !important;
}

.bg-main-app {
  background-color: #39ADAA !important;
}

.search-app-input {
  margin-left: 0px !important;

  &::placeholder {
    color: rgba(60, 60, 67, 0.6);
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.error-text {
  color: #ff5722;
  font-size: 13px;
  padding-left: 7px;
  display: block;
}

// modify from bootstrap
.btn.btn-primary, .btn-light {
  border: none;
}

.btn:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  border: none !important;
  min-width: 222px !important;
  box-shadow: rgb(0 0 0 / 15%) 4px 4px 8px, rgb(0 0 0 / 25%) 0px 5px 10px;
  border-radius: 10px;
  background: rgb(255 255 255 / 95%);

  .dropdown-item {
    line-height: 30px;
    font-size: 16px;
    padding: 5px 15px;
    font-weight: 400;
    i {
      font-size: 21px;
      margin-top: 0px;
    }
  }
}

#my-group-action {
  .dropdown-menu {
    min-width: 240px !important;
  }
}

.font-size-29 {
  font-size: 29px !important;
}

.user-chat-nav {
  i {
    font-size: 24px;
  }
  .nav-btn {
    width: unset !important;
    padding-left: 10px !important;
  }
}

.chat-leftsidebar {
  height: 96vh;
  width: 100vw;
  overflow-x: hidden;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}

.modal {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  .modal-content {
    background: rgba(255, 255, 255, 0.9);
    mix-blend-mode: normal;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow-y: scroll;

    .modal-header {
      border-bottom: none;
    }
    .modal-title {
      margin: auto;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #6C6C70;
    }
    .modal-body {
      font-size: 16px;
      padding: 0px 17px;

      input[type=text], input[type=password] {
        border: none;
        background-color: unset;
        border-bottom: 1px solid;
        border-radius: 0px;
        padding: 3px 5px;
        &::placeholder {
          text-transform:initial;
        }
      }
      input[type=text]:focus, input[type=password]:focus {
        border-color: #00A0B0;
      }
      input[type=text]::placeholder, input[type=password]::placeholder {
        color: #7b7c7d;
      }
      textarea::placeholder {
        color: #7b7c7d;
      }
    }
    .modal-footer {
      border-top: none;
      button {
        font-size: 15px;
        font-weight: 500;
      }
      button.btn-link:focus, button.btn-link:hover {
        text-decoration: none;
      }
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}

.input-group-text {
  border-radius: 0px !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif !important;
}

h6 {
  font-weight: 500 !important;
}

.btn-link {
  color: #39ADAA !important;
}


.confirm-delete-text {
  color: #ff5722 !important;
  font-weight: bold !important;
}


.btn-primary {
  background-color: #39ADAA !important;
  border-color: #39ADAA !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  &:focus {
    box-shadow: none !important;
  }
}

.form-check-input[type=checkbox] {
  border-radius: 0px;
}

.form-check-input:checked {
  background-color: #39ADAA !important;
  border-color: #39ADAA !important;
}

.global-button {
  background-color: #39ADAA;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px 40px;
  color: white;
}

a.global-item-link {
  color: #39adaa;
}

.global-input {
  padding: 10px 20px;
  width: 100%;
  color: #39adaa;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-size: 16px;
}

.global-input:focus-visible {
  outline: none;
  border-color: #39adaa;
}

.global-input:focus {
  outline: none;
  border-color: #39adaa;
}

.common-text-color {
  color: #6C6C70;
}

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: white !important;
}
