.init-message-chat-tab, .init-message-group-tab, .init-message-zero-group-tab {
  text-align: center;
  padding: 15px;
  .logo {
    width: 130px;
    text-align: center;
    margin-top: 50px;
  }

  p {
    font-size: 14px;
  }

  a.add-new-contact-btn {
    color: #39ADAA;
    background-color: #00a0b03d;
    border-radius: 15px;
    padding: 6px 15px;
    display: inline-flex;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
}

#addgroup-exampleModal {
  .modal-content {
    overflow: scroll;
    .contact-list li {
      padding: 0px 10px;
    }
  }
}

.user-chat-nav {
  .set-unpin-btn {
    margin-right: 3px;
    img {
      width: 18px;
      margin-bottom: 2px;
      filter: contrast(20%) saturate(3);
    }
  }
}

.chat-message-list {
  .chat-list {
    li {
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -2;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        transition: all 0.2s;
        z-index: -1;
      }
      &.clicked {
        color: #fff;
        &:before {
          width: 100%;
        }
      }

      .each-conversation-content {
        display: flex;
        align-items: center;
        .avatar-img {
          background-color: white;
          border-radius: 100%;
          border: 2px solid #00a0b03d;
          width: 45px;
          height: 45px;
          padding: 0;
          margin-right: 20px;
        }

        .chat-user-img {
          background-color: #00a0b03d;
          color: #00a0b0 !important;
          border-radius: 100%;
          box-shadow: 0px 0px 1px 1px #00a0b03d;
          -webkit-box-shadow: 0px 0px 1px 1px #00a0b03d;
          -moz-box-shadow: 0px 0px 1px 1px #00a0b03d;
          width: 45px;
          height: 45px;
          padding: 0;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
        }
      }

      .room-name {
        width: 85%;
        color: #000;
      }
      .chat-user-message {
        width: 85%;
      }

      .unread-message {
        right: 16px;
        top: 18px;

        .badge {
          width: 23px;
          height: 23px;
          line-height: 23px;
          padding: 0;
        }

        .pin-icon {
          font-size: 100%;
          padding: 0px;
          margin-right: 10px;
          color: #52b6ac;
        }
      }

      .round.open {
        visibility: visible;
        width: 35px
      }

      .round {
        position: relative;
        float: left;
        margin-top: 19px;
        margin-left: 16px;
        width: 0px;
        transition: all 0.1s linear;
        visibility: hidden;

        label {
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 22px;
          left: 0;
          position: absolute;
          top: 0;
          width: 22px;
          transition: all 0.2s linear;
        }

        label:after {
          border: 2.4px solid #39ADAA;
          border-top: none;
          border-right: none;
          content: "";
          height: 6px;
          left: 5px;
          opacity: 0;
          position: absolute;
          top: 2px;
          transform: rotate(-45deg);
          width: 17px;
        }

        input[type="checkbox"] {
          visibility: hidden;
        }

        input[type="checkbox"]:checked + label {
          border-color: #39ADAA;
        }

        input[type="checkbox"]:checked + label:after {
          opacity: 1;
        }
      }
      a {
        border-top: none;
      }
      a:hover {
        background-color: unset;
      }
    }
  }
}
