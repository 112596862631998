.gallery-column {
  .loading-gif {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 25%;
    left: 30%;
  }
}
.file-tab, .secured-book-tab, .conversation-tab {
  .gallery-column {
    position: relative;
    .add-more-block {
      background-color: #39ADAA;
      display: flex;
      vertical-align: middle;
      height: 100%;
      padding: 13px;
      border-radius: 5px;

      img {
        width: 50px;
        height: 50px;
        margin: auto;
      }
    }

    .each-block {
      background-color: #dddddd;
      height: 100%;
      padding: 10px;
      border-radius: 5px;

      .text-icon {
        position: absolute;
        right: 15px;
        top: 7px;
        font-size: 25px;
      }
      .text-title {
        margin-bottom: 3px;
        overflow-wrap: break-word;
        margin-top: 40px;
      }
      .text-time-create {
        font-size: 10px;
      }
    }
  }

  .add-file-block {
    text-align: center;
    i {
      font-size: 40px;
      color: #00A0B0;
    }
  }
}

#pills-vaults {
  #vault-upload-photo-form {
    .vault-gallery-camera {
      .vault-gallery-video {
        width: 100vw;
        height: auto;
        object-fit: cover;
        position: absolute;
        margin-top: 66px;
      }
    }

    #vault-screenshot img {
      margin-top: 66px;
      width: 100vw;
      object-fit: cover;
      position: absolute;
    }

    #vault-canvas {
      display: none;
    }
  }
  .detail-page {
    #vault-listing-gallery, #vault-listing-files, #vault-listing-secured-book, #vault-listing-conversations {
      margin-right: 0px;
      margin-left: 0;
      margin-bottom: 10px;
    }
    .attachment-tabs {
      height: calc(100vh - 120px);
    }

    .vault-topbar {
      height: 60px;
      display: grid;
      align-items: center;
      border-bottom: 1px solid #9e9e9e8c !important;

      .valt-search-block {
        display: flex;
        align-items: center;
        padding: 0 12px 0 8px;
        .back-btn {
          font-size: 29px;
          margin-right: 12px;
          font-weight: bold;
        }
        .keyword {
          width: 100%;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #9E9E9E;
          outline: none;
        }
        .close-search {
          font-size: 29px;
          margin-left: 12px;
        }
      }

      #vaut-main-topbar {
        display: flex;
        align-items: center;
        padding-right: 1rem;
        .search-note-icon {
          font-size: 23px;
        }
      }

      #vault-tool-topbar {
        font-size: 21px;
        padding: 0px 10px;
        display: flex;
        .clear-checked {
          display: contents;
          .vault-checkbox-topbar-remove {
            font-size: 26px;
            margin-right: 10px;
            background-color: transparent;
            color: #7a7f9a;
            border: none;
            margin-top: 2px;
          }
          .file-selected-number {
            color: #7a7f9a;
          }
        }
      }
    }
  }

  .listing-vault-tabs {
    padding-bottom: 68px;
    background-color: #f5f7fb;
  }

  .vault-tab {
    display: flex;
    padding: 5px 0px;

    .vault-tab-child {
      width: 100%;
      max-height: 150px;
      text-align: center;
      border-radius: 10px;
      background: linear-gradient(180deg, #39ADAA 0%, #3DBAB6 100%);
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }

      .vault-icon {
        margin: auto;
        border-radius: 50%;
        cursor: pointer;
        height: 55px;
        width: 55px;
        background-color: #00A0B0;
        padding-top: 4px;

        i {
          color: #FFF;
          font-size: 30px;
          margin-left: 0px;
        }
      }

      .vault-label {
        font-size: 20px;
        font-weight: 500;
        color: white;
        position: absolute;
        top: 6px;
        left: 14px;
      }

      &.checked {
        .vault-icon {
          border: 3px solid #fff;

          i {
            font-size: 30px;
            margin-left: 0px;
          }
        }

        .vault-label {
          color: #00A0B0;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  #pills-vaults {
    .chat-message-list {
      height: calc(100vh - 135px);
    }
  }
}
