#dark-mode {
  color: white;

  .chat-leftsidebar {
    background-color: #292929;
  }

  // common
  .btn-light {
    background-color: #39ADAA;
    color: white;
  }

  .text-body {
    color: #93a1ae !important;
  }

  .form-control {
    color: white;
  }

  input[type=text]::placeholder, input[type=password]::placeholder {
    color: #ababab;
  }

  textarea.enter-messsage-content::placeholder {
    color: #ababab;
  }

  .common-text-color {
    color: white;
  }

  .gray-bg-standard {
    background-color: #525357 !important;
  }

  h5 {
    color: #dbdbdb;
  }
  h6 {
    color: white;
  }
  .text-muted {
    color: #C4C4C4 !important;
  }

  // modal
  .modal .modal-content {
    background: #263238;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.25);
    border-color: #333f46;
    .modal-title {
      color: #c3c3ca;
    }

    .modal-body {
      color: #c9c9c9;
    }

    textarea {
      color: white;
      background: rgba(118, 118, 128, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
  // model end

  .rad-text {
    color: white;
  }

  // start modify botto menu
  //=========================
  .side-menu {
    background-color: #292929 !important;
  }

  .side-menu-nav .nav-item .nav-link.active {
    background-color: #f7f7ff3d;
    color: #3fbfbc;
  }
  //=========================
  // end modify botto menu

  // setting page
  #lifetime-duration-label {
    color: #c4c4c4;
  }
  #pills-setting {
    background: url(../images/template_dark.jpg);
    background-size: 200%;
    background-repeat: no-repeat;

    .top-page .row.wallet-group .wallet-col {
      background: rgba(250, 250, 250, 0.1);
      box-shadow: 8px 0px 18px rgba(0, 0, 0, 0.25);
    }
    .accordion-item {
      .accordion-button {
        background-color: #292929;
        .title-text {
          background: #3c3c3c;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .accordion-button:not(.collapsed) {
        .title-text {
          background: #292929;
          box-shadow: none;
          color: white;
        }
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url(../images/white-down-arrow.png);
      }
      .accordion-button::after {
        background-image: url(../images/white-down-arrow.png);
      }

      .accordion-body {
        background-color: #292929;
        .all-my-info {
          background: linear-gradient(180deg, #404040 0%, #2E2E2E 100%);
        }
      }
    }
  }

  // vault page

  #pills-vaults .listing-vault-tabs {
    background-color: #292929;
  }

  #pills-vaults {
    .detail-page {
      .vault-topbar {
        .valt-search-block {
          .keyword {
            color: white;
          }
        }
      }
    }
  }

  .file-tab .gallery-column .each-block, .secured-book-tab .gallery-column .each-block, .conversation-tab .gallery-column .each-block {
    background-color: #979797;
  }

  // contact page
  #pills-contacts .contact-list, #pills-requests .contact-list {
    background-color: #292929;
    color: white;
  }

  #pills-requests .top-header {
    color: #dbdbdb;
  }

  // group, chat list page
  .chat-list li .chat-user-message {
    color: #8f8f94;
  }

  .chat-message-list .avatar-title {
    background-color: #475a64;
  }

  .chat-message-list .chat-list li .round label {
    background-color: #fff0;
  }

  // conversation page
  .user-chat {
    background-color: #292929;
    .user-chat-topbar {
      .search-block {
        .search-input {
          color: white;
        }
      }
    }
  }

  #user-chat-show .chat-conversation, #attachment-chat .chat-conversation {
    background: url(../images/chat_bg_dark_new.jpeg);
    background-size: cover;
  }

  #user-chat-show ul#my-chat-history li.date-break-line p.date-content, #attachment-chat ul#my-chat-history li.date-break-line p.date-content {
    color: white;
  }

  #user-chat-show .chat-conversation .template .wrapper, #attachment-chat .chat-conversation .template .wrapper {
    background-color: #525357a8;
  }

  #user-chat-show {
    #message-content {
      .dropdown.suggest {
        .dropdown-menu {
          .dropdown-item {
            &.active {
              background-color: #184646;
            }
            &:active {
              background-color: #184646;
            }
            &:focus {
              background-color: #184646;
            }
            &:hover {
              background-color: #184646;
            }
          }
        }
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid #234e5c !important;
  }

  .border-bottom.reply-section-content {
    border-bottom: 0px solid #c8c8c8 !important;
  }

  .chat-input-section {
    background-color: #292929;
  }

  .chat-conversation .conversation-list {
    .chat-time {
      color: #d0d0d0;
    }

    .conversation-name {
      color: white;
    }

    .right .chat-time {
      color: #d0d0d0;
    }

    .ctext-wrap-content {
      color: white;
      background-color: #525357;
    }
    .ctext-wrap-content:before {
      border-left-color: #525357;
      border-top-color: #525357;
    }
  }
  .chat-conversation .right .conversation-list {
    .ctext-wrap-content {
      background-color: #39ADAA;
      color: #fff;
    }

    .ctext-wrap-content:before {
      border: 5px solid transparent;
      border-top-color: #39ADAA;
      border-right-color: #39ADAA;
      left: auto;
      right: 0;
    }
  }

  .dropdown-menu {
    background: #263238;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.12), 0px 1px 32px rgba(0, 0, 0, 0.12);

    .dropdown-item {
      color: #b2b2b8;
    }
  }

  #user-chat-show ul#my-chat-history li .round label, #attachment-chat ul#my-chat-history li .round label {
    background-color: #fff0;
    border: 2px solid #ccc;
  }

  #user-chat-show ul#my-chat-history li .round input[type=checkbox]:checked + label, #attachment-chat ul#my-chat-history li .round input[type=checkbox]:checked + label {
    border-color: #39ADAA;
  }

  #user-chat-show .chat-conversation.reply-section-open, #user-chat-show .chat-conversation.forward-section-open {
    height: calc(100vh - 223px);
  }

  .card {
    border: none !important;
    .card-header {
      background-color: #39ADAA;
      border-bottom: 0 solid #39ADAA;
      h5 {
        color: white;
      }
    }

    .card-body {
      background-color: #292929;
      .contact-list {
        padding-top: 5px;
        color: white;
      }
    }
  }

  .forward-chat, .attachment-chat {
    background-color: #292929;
    .attachment-topbar {
      border-bottom: 1px solid #696969 !important;
    }
  }

  .sign-up-background-common {
    background: url(../images/BG1_dark.png);
    background-size: auto 100%;
    color: white;

    .top-header {
      .title {
        color: white;
        font-weight: bold;
      }
      .back-btn {
        filter: none;
        width: 18px;
      }
    }
    input {
      background-color: #2b2b2c;
      border: 2px solid #9E9E9E;
      color: white;
    }
    input:focus {
      border: 2px solid #39ADAA;
    }
    .error-listing {
      color: white;
    }
  }


  // wallet
  .wallet-container {
    .voucher-list {
      background-color: #fff0;
      .voucher-card {
        background-image: linear-gradient(to right, #040303 , #848282);
        border: none;
        box-shadow: 0 4px 6px 0 rgb(248 243 243 / 20%);
        .content {
          color: white;
          h1 {
            color: white;
          }
        }
        .copy-button {
          input {
            background-color: unset;
            border: none;
          }
        }
      }
    }
  }

  // message template

  #message-template-list, #message-template-list .main, #transaction-list, .wallet-container {
    background: url(../images/BG1_dark.png);
    background-size: auto 100%;
    color: white;

    .top-header {
      .title {
        color: white;
        font-weight: bold;
      }
      .back-btn {
        filter: none;
        width: 18px;
      }
    }

    input, textarea {
      background-color: #2b2b2c;
      border: 2px solid #9E9E9E;
      color: white;
    }
    input:focus, textarea:focus {
      border: 2px solid #39ADAA;
    }
    input::placeholder, textarea::placeholder {
      color: #757575;
    }
    a {
      color: white;
    }
    ul.listing {
      color: white;
    }
  }

  // user profile
  .user-profile-sidebar {
    background-color: #263238;

    .accordion-button:not(.collapsed) {
      background-color: #39ADAA;
    }
    h5 {
      color: white;
    }

    .accordion-body {
      background-color: #292929;
    }
  }

  // open note
  .open-note-main {
    background-color: #292929;
    color: white;
    .content {
      background-color: #3d3d3e;
      color: white;
    }
  }

  // sucured book
  .edit-secured-book-main {
    background-color: #292929;
    color: white;

    .top-header {
      color: white;
    }

    input.title {
      color: white;
    }
    .spreadsheet-wrapper .spreadsheet {
      thead {
        background-color: #3d3d3e;
      }
      tbody th {
        background-color: #3d3d3e;
      }
      td {
        background: #292929;
      }

      .dropdown-content {
        background-color: #263238;
        p {
          color: #b2b2b8;
        }
      }
    }

    .continue-btn {
      color: #c7c2c2;
      &.active {
        color: #39ADAA;
      }
    }
  }
  .confirm-friend-modal {
    background-color: #292929;
    color: white;

    .main-container {
      .header {
        .close-btn {
          color: white;
        }
      }
      .avatar-block {
        background-color: white;
      }
    }
  }

  .open-broadcast-main {
    background-color: black;
    color: white;

    .back-btn {
      filter: brightness(1);
    }
  }
  #edit-avatar {
    color: white;
    background-color: black;
    .top-header {
      .back-btn {
        filter: brightness(1);
      }
    }
  }
  .edit-photo-modal {
    background-color: black;
    color: white;
    .main-container {
      .header {
        .close-btn {
          color: white;
        }
      }
    }
    .pintura-editor {
      --color-background: 0, 0, 0;
      --color-foreground: 255, 255, 255;
    }
  }
}
