.preload {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;
  img {
    margin-top: calc(50vh - 15px);
    margin-left: calc(50vw - 15px);
    width: 40px;
    height: 40px;
  }
}

.waiting-network-loading {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 9999;
  font-size: 16px;
  bottom: 0;

  .loading-content {
    width: 100%;

    .loading-wrapper {
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;

      img {
        width: 57px;
        margin-left: 20px;
      }
      .note-text {
        font-size: 16px;
        font-weight: 500;
        margin-left: 20px;
      }
    }
  }

}

.preload-export {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;

  .wrapper-export {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(50vh);

    img {
      width: 40px;
    }

    p {
      color: white;
      margin: 0px;
      margin-left: 10px;
    }
  }
}

.preload-encrypt, .preload-decrypt {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999;
  background-color: #37373d9e;
  img {
    margin-top: calc(50vh - 40px);
    margin-left: calc(50vw - 30px);
    width: 80px;
    height: 80px;
  }
}

// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
.notify-block {
  position: relative;
}

.notify-counter {
  width: 22px;
  height: 22px;
  background: #ef476f;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
  padding: 3px 0;
}

.request-counter {
  color: #ef476f;
  font-size: 16px;
  font-weight: bold;
}


.top-select-group-display {
  color: #7a7f9a;
  .cancel-select-group {
    position: absolute;
    display: none;
    font-size: 26px;
    line-height: 22px
  }
  .group-selected-number {
    padding-left: 60px;
  }
}

.side-menu {
  background-color: #ffffff !important;
  border-top: none !important;
}

#pills-setting .copy-clipboard-btn {
  display: block;
  float: right;
  padding-left: 10px;
}

.toast-block {
  position: absolute;
  width: 100%;
  bottom: 120px;
  z-index: 10000;

  .toast {
    color: #37404a;
    text-align: center;
    margin: auto;
  }
}

.chat-message-list {
  .avatar-title {
    background-color: #00a0b03d;
  }
}

@media (max-width: 991.98px) {
  #listing-group-forward.chat-message-list {
      height: calc(100vh - 133px);
  }
}
