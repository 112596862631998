// Place all the styles related to the wallet controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/
.wallet-container {
  background-color: white;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: black;

  .top-header {
    padding: 20px;
    padding-bottom: 0px;
    .title {
      font-size: 18px;
      font-weight: 500;
      display: inline-table;
      margin-top: 0px;
    }
    .back-btn {
      width: 15px;
      margin-right: 20px;
      margin-bottom: 5px;
      filter: invert(1);
    }
  }

  .month-wrapper {
    display: grid;
    padding: 10px;
    padding-bottom: 0px;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;

    .month-select-group {
      border: 1px solid white;
      border-radius: 8px;
      text-align: center;
      padding: 5px;
    }
    .month-select-group.active {
      background-color: #39ADAA;
      color: white;
    }

    .month-select-group.active h2 {
      color: white;
    }

    .month-select-group h2 {
      margin-bottom: 0px;
    }
    &.one-column {
      grid-template-columns: auto!important;
    }
  }

  .enter-voucher-block {
    margin-bottom: 12px;
    .notice-text {
      text-align: center;
      margin-bottom: 5px;
    }
    .input-voucher-group {
      display: flex;
    }
    .global-input {
      border-radius: 8px 0px 0px 8px;
      text-transform:uppercase;
    }
    .global-input::placeholder {
      text-transform:initial;
    }
    .submit-voucher-btn {
      margin-left: 5px;
      border: none;
      background-color: #39ADAA;
      border-radius: 0px 8px 8px 0px;
      font-weight: 500;
      padding: 0px 10px;
      color: white;
    }
  }

  .voucher-list {
    margin-top: 10px;
    padding: 20px 12px;
    background-color: #f5f7fb;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #9e9e9e;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: calc(100vh - 150px);

    .card-list {
      height: calc(100vh - 282px);
      overflow-y: scroll;

      .voucher-card {
        margin-top: 15px;
        border: solid 1px #ff5722;
        overflow: hidden;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        padding: 10px 10px;
        position: relative;
      }

      .copy-button {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
      }
      .voucher-card::after {
        position: absolute;
        content: "";
        height: 40px;
        right: -20px;
        border-radius: 40px;
        z-index: 1;
        top: 87px;
        background-color: #ff5722;
        width: 40px;
      }

      .voucher-card::before {
        position: absolute;
        content: "";
        height: 40px;
        left: -20px;
        border-radius: 40px;
        z-index: 1;
        top: 87px;
        background-color: #ff5722;
        width: 40px;
      }

      .horizontal {
        border-bottom: 2px dotted #ff5722;
        width: 100%;
        height: 3px;
      }

      .content {
        padding: 0px 18px;
        color: #565656;

        h1 {
          font-size: 35px;
          span {
            font-size: 18px;
          }
        }
        h2 {
          font-size: 18px;
          text-transform: uppercase;
        }
        p {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }

      .copy-button {
        margin: 0px 18px;
        margin-top: 5px;
        height: 45px;
        border-radius: 4px;
        padding: 0 5px;
        border: 2px dotted #ff5722;
      }

      .copy-button input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        margin: 10px;
      }

      .copy-button button {
        padding: 5px 20px;
        background-color: #ff5722;
        color: #fff;
        border: 1px solid transparent;
      }
    }
  }
}
