#pills-vaults {
  .start-camera {
    position: relative;
    background: black;
    height: 100vh;
    width: 100%;
  }
}
.gallery-label {
  width: 30px;
  height: 30px;
  position: absolute;
}

.gallery-label {
  position: absolute;
  opacity: 0.8;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  width: 28px;
  background-color: #9e9e9e7a;
  margin-top: 10px;
  margin-left: 10px;
  z-index: 9999;
}

.gallery-label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.is-video {
  position: absolute;
  left: 10px;
  bottom: 15px;
  color: white;
  background-color: #00000094;
  padding: 0px 10px;
  border-radius: 25px;
}

:checked+.gallery-label {
  background-color: #22c746;
  border-color: #ccc;
  opacity: 1;
}

:checked+.gallery-label:after {
  opacity: 1;
}

.gallery-checkbox {
  display: none;
}

.gallery-column {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 7px 7px;
  height: 150px;
  position: relative;
}

.gallery-column.photo-block {
  height: 200px;
}

.gallery-column.attachment-camera, .gallery-column.start-native-camera, .gallery-column.start-native-video {
  .take-photo-btn {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    background-color: #39ADAA;
  }

  i {
    color: white;
    position: absolute;
    left: calc(50% - 22px);
    top: calc(50% - 34px);
    font-size: 45px;
    opacity: 0.9;
  }
}

.gallery-column {
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#listing-gallery, #listing-files, #listing-secured-book {
  margin-right: -5px;
  margin-left: 0;
  margin-bottom: 10px;
}

.attachment-camera-section .gallery-video {
  width: 100vw;
  height: auto;
  object-fit: cover;
  position: absolute;
}

#screenshot img {
  width: 100vw;
  object-fit: cover;
  position: absolute;
}

#btnScreenshot,
#screenshot-send,
#screenshot-save {
  position: absolute;
  z-index: 1;
}

.btn-record-screenshot {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: 45px;
  left: calc(50vw - 30px);
  opacity: 0.8;
  border: 2px solid gray;
  background: white;
  color: black;
  position: absolute;
  z-index: 1;
}

#btn-record-video, #btnRecordVideo {
  &::before {
    width: 18px;
    height: 18px;
    background: red;
    border-radius: 50px;
    display: inline-block;
    content: "";
    margin-top: 5px;
  }
}
#btn-stop-and-save-video, #btnStopAndSaveVideo {
  display: none;
  &::before {
    width: 18px;
    height: 18px;
    background: gray;
    display: inline-block;
    content: "";
    margin-top: 5px;
  }
}

.attachment-camera-section-remove {
  position: absolute;
  z-index: 1;
  color: white;
  font-size: 35px;
  top: 6px;
  left: 15px;
  &:hover {
    color: white;
  }
}

.flash-light-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  color: white;
  border: none;
  font-size: 28px;
  &.active {
    color: #33f3ee !important;
  }
  &:hover {
    color: white;
  }
}

#screenshot-send {
  bottom: 4%;
  right: 5%;
}

#screenshot-save {
  bottom: 45px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  left: calc(50% - 30px);
  i {
    font-size: 26px;
  }
}

.attachment-camera-chat {
  background-color: #000000;
  #screenshot {
    margin-top: 65px;
  }
}

#canvas {
  display: none;
}

.attachment-chat-section.attachment-chat-section {
  display: none;
}

.attachment-tabs {
  height: calc(100vh - 162px);
}

.attachment-topbar {
  height: 60px;
}

.attachment-tab {
  display: flex;
  padding: 4px 5px;

  .attachment-tab-child {
    margin-right: 15px;
    padding: 15px;
    border-radius: 20%;
    text-align: center;

    .attachment-icon {
      margin: auto;
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      width: 40px;
      background-color: #00A0B0;

      i {
        color: #FFF;
        font-size: 24px;
        margin-left: 0px;
      }
    }

    .attachment-label {
    }

    &.checked {
      .attachment-icon {
        border: 3px solid #fff;

        i {
          font-size: 23px;
          margin-left: 0px;
        }
      }

      .attachment-label {
        color: #00A0B0;
      }
    }
  }
}

.switch-video-photo {
  position: absolute;
  bottom: 125px;
  left: calc(50vw - 85px);
  color: white;
  font-size: 18px;
  background-color: black;
  padding: 5px 20px;
  border-radius: 25px;
  .active {
    color: #39ADAA;
    font-weight: bold;
  }
}
