#open-broadcast, #edit-broadcast {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: 1000;
}

.open-broadcast-main {
  background-color: white;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: black;

  .wrapper {
    padding: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid;
  }

  .broadcast-name-block {
    padding: 10px 20px;
    input[type=text] {
      border: none;
      background-color: transparent;
      border-bottom: 1px solid;
      border-radius: 0px;
      padding: 3px 0px
    }
    input[type=text]:focus {
      border-color: #39ADAA;
    }
    input[type=text]::placeholder {
      color: #7b7c7d;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    display: inline-table;
    margin-top: 0px;
  }

  .top-header {
    display: flow-root;
  }

  .back-btn {
    width: 15px;
    margin-right: 20px;
    margin-bottom: 4px;
    filter: brightness(0);
  }

  .continue-btn {
    color: #39ADAA;
    font-size: 14px;
    float: right;
    font-weight: bold;
    padding-top: 4px;
    background: none;
    border: none;
  }

  .selected-list {
    min-height: 70px;
    padding: 10px 20px;
    p.init-text {
      color: #9e9e9e;
      padding: 19.5px 10px;
      text-align: center;
    }

    .selected-user-name {
      display: flow-root;
      overflow: scroll;
      height: 100px;
      color: white;
      span {
        display: block;
        float: left;
        padding: 3px 8px;
        margin-right: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 31%;
        border-radius: 15px;
      }
    }
  }

  .user-list {
    margin: 0;
    padding: 10px;
    li.each-user {
      list-style: none;
      .user-name {
        margin-top: 8px;
      }

      .avatar-xs {
        position: relative;

        label.checked {
          background-color: #22c746;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 20px;
          left: 20px;
          position: absolute;
          top: 20px;
          width: 20px;
        }

        label.checked:after {
          border: 2px solid #fff;
          border-top: none;
          border-right: none;
          content: "";
          height: 5px;
          left: 5px;
          opacity: 1;
          position: absolute;
          top: 6px;
          transform: rotate(-45deg);
          width: 9px;
        }
      }
    }
  }
}
